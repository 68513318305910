import { useEffect, useState } from "react";
import { formatUnits } from "viem";
import { useBlockNumber } from "wagmi";

import { useSubgraphBlockNumber } from "@/hooks/useSubgraphBlockNumber";
import { PingDot } from "./ping-dot";
import { BasicTooltip } from "./basic-tooltip";

type Status = "ok" | "delay" | "error";
const colors: Record<Status, string> = {
  ok: "bg-green-500",
  delay: "bg-yellow-500",
  error: "bg-red-500",
};

export const LatestBlock = () => {
  const [status, setStatus] = useState<Status>("ok");
  const [lag, setLag] = useState(0);

  const rpcBlockNum = useBlockNumber({
    watch: { pollingInterval: 20_000, poll: true, enabled: true },
  });
  const { blockNum, blockTimestamp, hasIndexingErrors } =
    useSubgraphBlockNumber();

  useEffect(() => {
    if (blockTimestamp) {
      const now = new Date().getTime() / 1000;
      const lag = blockTimestamp ? now - blockTimestamp : 0;
      setLag(lag);

      if (hasIndexingErrors) {
        setStatus("error");
      } else if (lag > 5 * 60 * 60) {
        setStatus("error");
      } else if (lag > 20) {
        setStatus("delay");
      } else {
        setStatus("ok");
      }
    }
  }, [blockNum, blockTimestamp, hasIndexingErrors]);

  return (
    <div className="flex items-center">
      <BasicTooltip
        className="bg-secondary"
        content={
          <>
            <div className="ml-1 text-xs">
              {lag.toFixed(2)}s delay since block{" "}
              {rpcBlockNum.data ? formatUnits(rpcBlockNum.data, 0) : "..."}
            </div>
          </>
        }
      >
        <div className="flex items-center">
          <PingDot heightClass="h-2" widthClass="w-2" color={colors[status]} />
          <div className="ml-2 text-xs">{blockNum}</div>
        </div>
      </BasicTooltip>
    </div>
  );
};
