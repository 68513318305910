export const HttpModeWarning: React.FC = () => {
  return (
    <div className="flex flex-col p-1 text-center bg-muted dark:bg-black">
      <h1>Insecure Connection</h1>
      <p>
        This application requires a secure (HTTPS) connection to function
        properly. Please access the application over a secure connection.
      </p>
    </div>
  );
};
