import { RouterProvider } from "react-router-dom";
import { WagmiProvider } from "@privy-io/wagmi";
import { PrivyProvider } from "@privy-io/react-auth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider as FallbackWagmiProvider } from "wagmi";

import { config, privyConfig } from "./config/config";
import { HttpModeWarning } from "./components/http-mode-warning";
import { ApolloProviderWithClient } from "./contexts/subgraph-provider";
import { ThemeProvider } from "./contexts/theme-provider";
import { router } from "./Router";

const queryClient = new QueryClient();

function App() {
  // Provide fallback for testing when app is loaded locally over http
  const isHttps = window.location.protocol === "https:";
  if (!isHttps) {
    return (
      <ThemeProvider>
        <QueryClientProvider client={queryClient}>
          <FallbackWagmiProvider config={config}>
            <ApolloProviderWithClient>
              <HttpModeWarning />
              <RouterProvider router={router} />
            </ApolloProviderWithClient>
          </FallbackWagmiProvider>
        </QueryClientProvider>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider>
      <PrivyProvider
        appId={import.meta.env.VITE_PRIVY_APP_ID}
        config={privyConfig}
      >
        <QueryClientProvider client={queryClient}>
          <WagmiProvider config={config}>
            <ApolloProviderWithClient>
              <RouterProvider router={router} />
            </ApolloProviderWithClient>
          </WagmiProvider>
        </QueryClientProvider>
      </PrivyProvider>
    </ThemeProvider>
  );
}

export default App;
