import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/browser";

import { Toaster } from "./components/toaster";
import App from "./App.tsx";
import "./index.css";

Sentry.init({ dsn: import.meta.env.VITE_GLITCHTIP_DSN });

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <App />
    <Toaster />
  </StrictMode>,
);
