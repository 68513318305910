import { useMemo } from "react";

import { useOraclePrice } from "@/hooks/useOraclePrice";
import {
  calcPoolHealth,
  calcPoolRatio,
  calcSedgeControlPoints,
} from "@/lib/pool-attributes";
import { capitalize } from "@/lib/utils";
import { StatusDot } from "../status-dot";
import { Token, PoolParams, PoolHealth } from "@/lib/pool-types";

interface Props {
  baseToken: Token;
  quoteToken: Token;
  params: PoolParams;
}
export const PoolStatus: React.FC<Props> = ({
  baseToken,
  quoteToken,
  params,
}) => {
  const { oraclePrice } = useOraclePrice(baseToken, quoteToken);

  const status = useMemo<PoolHealth>(() => {
    if (oraclePrice) {
      // calculate ratio of pool derivatives
      const [baseRatio] = calcPoolRatio(baseToken, quoteToken, oraclePrice);

      // calculate pool's curve parameters
      const { slippageZones } = calcSedgeControlPoints(100, 0.5, 0.5, params);

      // calculate pool health
      return calcPoolHealth(baseRatio, slippageZones[0].x, slippageZones[0].y);
    }
    return "empty";
  }, [baseToken, quoteToken, params, oraclePrice]);

  const dotColor =
    status === "empty"
      ? "bg-gray-500"
      : status === "balanced"
      ? "bg-emerald-500"
      : "bg-amber-500";

  return (
    <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-4 h-24">
      <div className="flex items-center justify-between mb-3">
        <span className="text-gray-400 text-sm font-medium">Pool Status</span>
      </div>
      <div className="flex items-center gap-2">
        <StatusDot className={dotColor} />
        <span className="font-medium">{capitalize(status)}</span>
      </div>
    </div>
  );
};
