import { NavLink } from "react-router-dom";

import { Logo } from "@/components/logo";
import { MainMenu } from "@/config/menu";
import { cn } from "@/lib/utils";
import { MobileMenu } from "./mobile-menu";
import { ConnectWalletButton } from "./connect-wallet-button";

export const Header: React.FC = () => {
  return (
    <>
      <div className="mt-8">
        {/* Small Header */}
        <div className="sm:hidden flex items-center justify-between w-full">
          <NavLink
            aria-label="Home"
            to="/"
            className="flex items-center space-x-2"
          >
            <img
              src="/assets/sedge-mobile-short.svg"
              className="w-[7rem]"
              alt="Sedge Mobile"
            />
          </NavLink>

          <MobileMenu />
        </div>
      </div>

      <div className="hidden sm:flex items-center">
        {/* Fixed size logo */}
        <div className="flex-shrink-0">
          <NavLink to="/" className="flex items-center space-x-2">
            <Logo />
          </NavLink>
        </div>

        {/* Links */}
        <nav className="flex flex-grow justify-center space-x-8">
          {MainMenu.map((menu, index) => (
            <NavLink
              key={index}
              to={menu.to ?? ""}
              className={({ isActive }) =>
                cn(
                  "text-xl font-medium transition-colors hover:text-primary",
                  isActive ? "text-foreground" : "text-foreground/60"
                )
              }
            >
              {menu.title}
            </NavLink>
          ))}
        </nav>

        <div className="flex-shrink-0">
          <ConnectWalletButton />
        </div>
      </div>
    </>
  );
};
