import { useEffect, useMemo, useState } from "react";
import { Address, formatUnits, getAddress } from "viem";

import { useGetPoolsDataQuery } from "@/generated/sedge-graphql";
import { PoolParams, Token } from "@/lib/pool-types";
import { usePoolsApr } from "./usePoolsApr";

export interface SubgraphPool {
  id: Address;
  lptLabel: string;
  lptSymbol: string;
  lptDecimals: number;
  lptPrice: number;
  lptTotal: string;
  assets: Address[];
  quoteOracle: Address;
  baseOracle: Address;
  oraclePriceDecimals: number;
  fee: string;
  apr: number;
  reserveQuote: number;
  reserveBase: number;
  reserveUsd: number;
  params: PoolParams;
}

interface SubgraphOracle {
  id: Address;
  token: string;
  decimals: number;
}

type PoolData = {
  pools: Record<Address, SubgraphPool>;
  oracles: Record<Address, SubgraphOracle>;
  tokens: Record<Address, Token>;
  poolAddrs: Address[];
  oracleAddrs: Address[];
  tokenAddrs: Address[];
};

const initState = () =>
  ({
    pools: {},
    oracles: {},
    tokens: {},
    poolAddrs: [],
    oracleAddrs: [],
    tokenAddrs: [],
  }) as PoolData;

export const usePools = () => {
  const [data, setData] = useState<PoolData>(initState());

  const aprData = usePoolsApr(data.pools);

  // pool summary subgraph query
  const {
    data: rawSubgraphData,
    // loading: subgraphLoading,
    error: subgraphError,
  } = useGetPoolsDataQuery();

  useEffect(() => {
    if (subgraphError) {
      console.log(subgraphError);
    }
  }, [subgraphError]);

  // Map summary subgraph query to base PoolData object
  useEffect(() => {
    if (rawSubgraphData) {
      const _data = rawSubgraphData?.pairs.reduce<PoolData>((prev, curr) => {
        const poolAddr = getAddress(curr.id);
        const token0Addr = getAddress(curr.token0.id);
        const token1Addr = getAddress(curr.token1.id);
        const oracle0Addr = getAddress(curr.token0.oracle.id);
        const oracle1Addr = getAddress(curr.token1.oracle.id);

        const token0Amount = Number(curr.reserve0);
        const token1Amount = Number(curr.reserve1);
        const token0Symbol = curr.token0.symbol;
        const token1Symbol = curr.token1.symbol;

        const lptLabel = `${token0Symbol}/${token1Symbol}`;
        const lptSymbol = `${token0Symbol.toLowerCase()}-${token1Symbol.toLowerCase()}-lpt`;

        const pool: SubgraphPool = {
          id: poolAddr,
          lptLabel,
          lptSymbol,
          lptDecimals: curr.decimals,
          lptPrice:
            Number(curr.reserveUSD) > 0
              ? Number(curr.reserveUSD) / Number(curr.totalLPToken)
              : 1,
          lptTotal: curr.totalLPToken,
          assets: [token0Addr, token1Addr],
          quoteOracle: oracle0Addr,
          baseOracle: oracle1Addr,
          oraclePriceDecimals: 6,
          fee: formatUnits(curr.epsilon, 18),
          apr: 0, // placeholder
          reserveBase: Number(curr.reserve0),
          reserveQuote: Number(curr.reserve1),
          reserveUsd: Number(curr.reserveUSD),
          params: {
            alpha: Number(curr.alpha),
            beta: Number(curr.beta),
            delta: Number(curr.delta),
            epsilon: Number(curr.epsilon),
            lambda: Number(curr.lambda),
          },
        };
        const token0: Token = {
          id: token0Addr,
          oracle: oracle0Addr,
          decimals: Number(curr.token0.decimals),
          name: curr.token0.name,
          amount: token0Amount,
          symbol: curr.token0.symbol,
          icon: `/assets/${curr.token0.symbol}.svg`,
        };
        const token1: Token = {
          id: token1Addr,
          oracle: oracle1Addr,
          decimals: Number(curr.token1.decimals),
          name: curr.token1.name,
          amount: token1Amount,
          symbol: curr.token1.symbol,
          icon: `/assets/${curr.token1.symbol}.svg`,
        };
        const oracle0: SubgraphOracle = {
          id: oracle0Addr,
          token: token0Addr,
          decimals: curr.token0.oracle.decimals,
        };
        const oracle1: SubgraphOracle = {
          id: oracle1Addr,
          token: token1Addr,
          decimals: curr.token1.oracle.decimals,
        };

        prev.pools[poolAddr] = pool;
        prev.tokens[token0Addr] = token0;
        prev.tokens[token1Addr] = token1;
        prev.oracles[oracle0Addr] = oracle0;
        prev.oracles[oracle1Addr] = oracle1;

        return prev;
      }, initState());

      _data.poolAddrs = Object.keys(_data.pools) as Address[];
      _data.tokenAddrs = Object.keys(_data.tokens) as Address[];
      _data.oracleAddrs = Object.keys(_data.oracles) as Address[];

      setData(_data);
    }
  }, [rawSubgraphData]);

  // Augment PoolData with APR
  const pools = useMemo<Record<Address, SubgraphPool>>(() => {
    if (data.poolAddrs && data.pools) {
      const p: Record<Address, SubgraphPool> = {};
      data.poolAddrs.forEach((addr) => {
        p[addr] = { ...data.pools[addr] };
        p[addr].apr = aprData[addr] ? Number(aprData[addr].apr) : 0;
      });
      return p;
    }
    return data.pools;
  }, [data.pools, data.poolAddrs, aprData]);

  return {
    ...data,
    pools,
  };
};
