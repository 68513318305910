import { useCallback, useEffect, useState } from "react";
import { useAtomValue } from "jotai";
import { useReadContract, useWriteContract } from "wagmi";

import { IDfxCurveV3Abi } from "@/abi/IDfxCurveV3";
import { txSettingsAtom } from "@/contexts/atoms";
import { Pool } from "@/lib/pool-types";
import { getDeadlineEpochTime } from "@/lib/time-utils";
import { toW3Number, W3Number } from "@/lib/w3Num";
import { toast } from "../useToast";

export const useLiquidityWithdrawTwoSided = (
  pool: Pool,
  lptAmount: W3Number | null
) => {
  const [baseTokenAmount, setBaseTokenAmount] = useState<W3Number | null>(null);
  const [quoteTokenAmount, setQuoteTokenAmount] = useState<W3Number | null>(
    null
  );
  const txSettings = useAtomValue(txSettingsAtom);

  const { data, isError, failureReason } = useReadContract({
    abi: IDfxCurveV3Abi,
    address: pool.id,
    functionName: "viewWithdraw",
    args: [lptAmount ? lptAmount.big : 0n],
    query: {
      enabled: !!lptAmount && lptAmount.big > 0n,
    },
  });
  const { writeContractAsync } = useWriteContract();

  useEffect(() => {
    if (isError) {
      console.log(failureReason?.shortMessage);
    }
  }, [isError, failureReason]);

  useEffect(() => {
    if (data) {
      const [amountA, amountB] = data;
      setBaseTokenAmount(toW3Number(amountA, pool.baseToken.decimals, 6));
      setQuoteTokenAmount(toW3Number(amountB, pool.baseToken.decimals, 6));
    }
  }, [data, pool.baseToken.decimals, setBaseTokenAmount, setQuoteTokenAmount]);

  // Watch for user clearing input boxes
  // TODO: still needed?
  useEffect(() => {
    if (lptAmount === null) {
      setBaseTokenAmount(null);
      setQuoteTokenAmount(null);
    }
  }, [lptAmount]);

  const withdraw = useCallback(async () => {
    if (lptAmount) {
      toast({ title: "Removing liquidity..." });
      await writeContractAsync({
        abi: IDfxCurveV3Abi,
        address: pool.id,
        functionName: "withdraw",
        args: [
          lptAmount.big,
          BigInt(getDeadlineEpochTime(txSettings.minutesToTxDeadline)),
        ],
      });
      toast({
        title: `Successfully removed ${pool.quoteToken.symbol}/${pool.baseToken.symbol} liquidity`,
      });
    }
  }, [
    pool.id,
    pool.baseToken.symbol,
    pool.quoteToken.symbol,
    txSettings.minutesToTxDeadline,
    lptAmount,
    writeContractAsync,
  ]);

  return {
    baseTokenAmount,
    quoteTokenAmount,
    withdraw,
  };
};
