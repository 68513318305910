import { ModeToggle } from "./mode-toggle";
import { LatestBlock } from "./latest-block";

export const Footer: React.FC = () => {
  return (
    <div className="flex-col">
      {/* Link Icons */}
      <div className="flex flex-col py-8">
        <div className="flex mt-8 justify-center items-center gap-4">
          <div className="opacity-50">
            <LatestBlock />
          </div>
          <ModeToggle />

          <div className="opacity-50 text-xs">rev:{__GIT_HASH__}</div>
        </div>
      </div>
    </div>
  );
};
