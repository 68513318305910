import { calcDisplayFees, useFees } from "@/hooks/useFees";
import { Token } from "@/lib/pool-types";
import { formatDecimalString } from "@/lib/utils";
import { W3Number } from "@/lib/w3Num";

const getSpotOracleRateDiff = (
  spotPrice: number | null,
  oraclePrice: number | null
): string | null => {
  if (spotPrice && oraclePrice) {
    const ratioDiff = 1 - spotPrice / oraclePrice;
    return formatDecimalString(Math.abs(ratioDiff * 100), 0, 2);
  }
  return "--";
};

const getSpotOracleTokenDiff = (
  spotPrice: number | null,
  oraclePrice: number | null,
  inAmount: W3Number
): string | null => {
  if (spotPrice && oraclePrice) {
    return formatDecimalString(
      Math.abs(inAmount.dsp / oraclePrice - inAmount.dsp / spotPrice),
      0,
      2
    );
  }
  return "--";
};

interface Props {
  inToken: Token;
  inAmount: W3Number;
  outToken: Token;
  outAmount: W3Number | null;
  spotPrice: number | null;
  oraclePrice: number | null;
}
export const SwapInfo: React.FC<Props> = ({
  inToken,
  inAmount,
  outToken,
  outAmount,
  spotPrice,
  oraclePrice,
}) => {
  const { totalFee } = useFees(inToken, outToken);

  return (
    <div className="text-sm">
      <div className="flex">
        <div>Min. Received</div>
        <div className="flex-grow text-end">
          {outAmount && outAmount.hStr} {outToken.symbol}
        </div>
      </div>

      <div className="flex">
        <div>Fees</div>
        <div className="flex-grow text-end">
          {calcDisplayFees(inAmount.dsp.toString(), totalFee)} {inToken.symbol}
        </div>
      </div>

      <div className="flex">
        <div>Swap Rate</div>
        <div className="flex-grow text-end">
          1 {inToken.symbol} = {formatDecimalString(spotPrice, 0, 4)}{" "}
          {outToken.symbol}
        </div>
      </div>

      <div className="flex">
        <div>Oracle Rate</div>
        <div className="flex-grow text-end">
          1 {inToken.symbol} = {formatDecimalString(oraclePrice, 0, 4)}{" "}
          {outToken.symbol}
        </div>
      </div>

      <div className="flex">
        <div>
          {oraclePrice && spotPrice && spotPrice - oraclePrice > 0
            ? "Oracle Bonus"
            : "Oracle Slippage"}
        </div>
        <div className="flex-grow text-end">
          {getSpotOracleRateDiff(spotPrice, oraclePrice)}% (
          {getSpotOracleTokenDiff(spotPrice, oraclePrice, inAmount)}{" "}
          {outToken.symbol})
        </div>
      </div>
    </div>
  );
};
