import { useState } from "react";
import { useAtom } from "jotai";
import { X } from "lucide-react";

import { txSettingsAtom } from "@/contexts/atoms";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { DialogContent } from "@/components/ui/override/dialog";
import { cn } from "@/lib/utils";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
export const EditTxSettingsModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const [txSettings, setTxSettings] = useAtom(txSettingsAtom);
  const [inputSource, setInputSource] = useState<"radio-group" | "input">(
    "radio-group",
  );

  const handleSlippageRadioGroupValue = (val: string) => {
    setTxSettings((state) => ({
      ...state,
      maxSlippagePercent: val,
    }));
    setInputSource("radio-group");
  };

  const handleSlippageInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) <= 100) {
      setTxSettings((state) => ({
        ...state,
        maxSlippagePercent: e.target.value,
      }));
      setInputSource("input");
    }
  };

  const handleTimeoutInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) >= 0) {
      setTxSettings((state) => ({
        ...state,
        minutesToTxDeadline: e.target.value,
      }));
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="dark:bg-gray-900 dark:border-gray-800 max-w-xl">
        <DialogHeader className="border-b border-gray-800 pb-4">
          <div className="flex items-center justify-between">
            <DialogTitle className="text-lg font-normal">
              Transaction Settings
            </DialogTitle>
            <Button
              variant="ghost"
              size="icon"
              onClick={onClose}
              className="hover:bg-gray-100 dark:hover:bg-gray-800"
            >
              <X className="h-5 w-5" />
            </Button>
          </div>
        </DialogHeader>
        <DialogDescription>
          Adjust the allowable price slippage and expiration timeout for your
          transactions.
        </DialogDescription>
        <div className="grid gap-4 pt-4">
          <div className="flex flex-col">
            <div>Max Price Slippage</div>
            <div className="px-4 grid grid-cols-4 gap-4 items-center">
              <div className="col-span-3">
                <RadioGroup
                  onValueChange={handleSlippageRadioGroupValue}
                  value={
                    inputSource === "radio-group"
                      ? txSettings.maxSlippagePercent
                      : ""
                  }
                  required={false}
                  orientation="horizontal"
                  className="grid-flow-col"
                >
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="0.1" id="slippage-0" />
                    <Label htmlFor="slippage-0">0.1%</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="0.5" id="slippage-1" />
                    <Label htmlFor="slippage-1">0.5%</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="1.0" id="slippage-2" />
                    <Label htmlFor="slippage-2">1.0%</Label>
                  </div>
                </RadioGroup>
              </div>
              <div className="flex items-center gap-x-1">
                <input
                  placeholder="Custom"
                  value={
                    inputSource === "input" ? txSettings.maxSlippagePercent : ""
                  }
                  onChange={handleSlippageInputValue}
                  className={cn(
                    "w-full bg-transparent p-2 h-10 focus:outline-none border rounded-md",
                    "text-right placeholder-shown:text-left",
                    "text-foreground text-md sm:text-md",
                    "dark:border-gray-900 dark:bg-gray-800",
                  )}
                />
                %
              </div>
            </div>
          </div>

          <div className="flex flex-col">
            <div>Timeout (minutes)</div>
            <div className="flex flex-col p-4">
              <input
                value={txSettings.minutesToTxDeadline}
                onChange={handleTimeoutInputValue}
                className={cn(
                  "p-2 h-10 focus:outline-none rounded-md",
                  "text-right",
                  "border-gray-200 border-[1px] dark:border-gray-900 dark:bg-gray-800",
                )}
              />
            </div>
          </div>
        </div>
        <DialogFooter className="px-4">
          <Button onClick={onClose}>Close</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
