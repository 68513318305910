import { ExternalLinkIcon } from "@radix-ui/react-icons";
import { Address } from "viem";
import { CurrencyPairIcon } from "../currency-pair-icon";

interface Props {
  id: Address;
  pairLabel: string;
  baseIcon: string;
  quoteIcon: string;
  tvl: number;
}

export const PoolTitleXxs: React.FC<Props> = ({
  id,
  baseIcon,
  quoteIcon,
  pairLabel,
  tvl,
}) => {
  return (
    <div className="flex items-center gap-2">
      <CurrencyPairIcon baseIcon={baseIcon} quoteIcon={quoteIcon} width={20} />
      <div className="flex flex-col">
        <div className="flex items-center font-medium text-sm">
          {pairLabel}
          <a
            href={`https://basescan.org/address/${id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 transition-colors"
            aria-label={`View ${pairLabel} on Basescan`}
          >
            <ExternalLinkIcon className="h-3 w-3" />
          </a>
        </div>
        <span className="font-medium text-emerald-950 dark:text-blue-50">
          ${tvl.toLocaleString()}
        </span>
      </div>
    </div>
  );
};
