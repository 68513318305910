import { useState, useEffect } from "react";
import { StackIcon } from "@radix-ui/react-icons";
import { usePrivy, useWallets } from "@privy-io/react-auth";
import { useAccount } from "wagmi";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { toast } from "@/hooks/useToast";
import { Disconnect } from "./disconnect";
import { NetworkSelect } from "./network-select";

const shortenAddr = (address: string | undefined) => {
  if (!address) return "";
  return `${address.substring(0, 6)}...${address.substring(
    address.length - 4,
    address.length
  )}`;
};

export const ConnectWalletButton = () => {
  const [walletAddress, setWalletAddress] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  const { connectWallet } = usePrivy();
  const { wallets, ready: walletsReady } = useWallets();

  // WAGMI hooks
  const { address, isConnected } = useAccount();

  useEffect(() => {
    // infer the Safe is autoconnected when address exists but no Privy wallets are connected
    if (address && wallets.length === 0) {
      setWalletAddress(address);
    }
    // set primary Privy wallet as connected addresses
    else if (walletsReady && wallets.length > 0) {
      setWalletAddress(wallets[0].address);
    }
  }, [address, wallets, walletsReady]);

  const handleButtonClick = () => {
    if (walletAddress && isConnected) {
      setShowDropdown(!showDropdown);
    } else {
      connectWallet();
    }
  };

  const handleCopyAddress = (addr: string) => {
    navigator.clipboard.writeText(addr);
    toast({
      title: "Address copied",
    });
  };

  return (
    <div className="flex flex-col xs:flex-row justify-center md:justify-end items-center sm:flex-wrap flex-wrap">
      <div className="mr-2 mb-2">
        <NetworkSelect />
      </div>

      <div className="mb-2">
        <Select>
          <SelectTrigger
            aria-label="Connect wallet"
            className="bg-primary text-white px-4 py-2 rounded-sm font-semibold"
            onClick={handleButtonClick}
          >
            <SelectValue
              placeholder={
                isConnected && walletAddress ? (
                  <div className="flex items-center [&>svg]:text-primary-foreground/80">
                    <div className="ml-2">{shortenAddr(walletAddress)}</div>
                  </div>
                ) : (
                  "Connect Wallet"
                )
              }
            />
          </SelectTrigger>

          {isConnected && (
            <SelectContent>
              <SelectGroup>
                <div className="p-[2px]">
                  <div className="pt-3 pb-6">
                    {walletsReady &&
                      wallets.map((wallet) => (
                        <div
                          className="flex min-w-full flex-row flex-wrap items-center justify-between px-2"
                          key={wallet.address}
                        >
                          <span className="text-sm text-secondary dark:text-muted-foreground">
                            {shortenAddr(wallet.address)}
                          </span>

                          <button
                            aria-label="Copy address"
                            type="button"
                            onClick={() => handleCopyAddress(wallet.address)}
                            className="cursor-copy [&>svg]:text-secondary dark:[&>svg]:text-muted-foreground"
                          >
                            <StackIcon />
                          </button>
                        </div>
                      ))}
                  </div>

                  {isConnected && address && <Disconnect />}
                </div>
              </SelectGroup>
            </SelectContent>
          )}
        </Select>
      </div>
    </div>
  );
};
