import { useEffect, useMemo, useState } from "react";

import { Token } from "@/lib/pool-types";
import { usePools } from "./usePools";

export const useSwapRoute = (inToken: Token | null, outToken: Token | null) => {
  const [usdcToken, setUsdcToken] = useState<Token | null>(null);

  const { pools, poolAddrs, tokens, tokenAddrs } = usePools();

  if (
    inToken &&
    outToken &&
    !(inToken.symbol === "USDC" || outToken.symbol === "USDC")
  ) {
    throw new Error("Non-USDC routes not supported.");
  }

  useEffect(() => {
    const usdcAddr = tokenAddrs.find((t) => tokens[t].symbol === "USDC");
    if (usdcAddr) {
      setUsdcToken(tokens[usdcAddr]);
    }
  }, [tokens, tokenAddrs]);

  const { poolRoute, tokenRoute } = useMemo(() => {
    if (inToken && outToken && usdcToken) {
      const inIsUsdc = inToken.id === usdcToken.id;
      const searchToken = inIsUsdc ? inToken : outToken;
      const matchPoolAddr = poolAddrs.find((p) => {
        const matchTokenAddr = pools[p].assets.find(
          (t) => tokens[t].id === searchToken.id
        );
        return matchTokenAddr !== undefined;
      });

      if (matchPoolAddr) {
        const matchPool = pools[matchPoolAddr];
        const tokenRoute = inIsUsdc
          ? [matchPool.assets[1], matchPool.assets[0]]
          : matchPool.assets;
        return {
          poolRoute: [matchPool],
          tokenRoute,
        };
      }
    }
    return {
      poolRoute: [],
      tokenRoute: [],
    };
  }, [inToken, outToken, pools, poolAddrs, tokens, usdcToken]);

  return {
    poolRoute,
    tokenRoute,
  };
};
