import { ExternalLinkIcon } from "@radix-ui/react-icons";
import { Token } from "@/lib/pool-types";
import { capitalize } from "@/lib/utils";

interface Props {
  side: "base" | "quote";
  token: Token;
}

export const PoolDerivative: React.FC<Props> = ({ side, token }) => {
  return (
    <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-4">
      <div className="flex items-center justify-between mb-3">
        <span className="text-gray-400 text-sm font-medium">
          {capitalize(side)} Token
        </span>
        <a
          href={`https://basescan.org/address/${token.id}`}
          target="_blank"
          className="text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 transition-colors"
        >
          <ExternalLinkIcon className="h-4 w-4" />
        </a>
      </div>
      <div className="flex items-center gap-3">
        <img src={token.icon} alt={token.symbol} className="w-8 h-8" />
        <span className="font-medium">
          {token.amount.toLocaleString()} {token.symbol}
        </span>
      </div>
    </div>
  );
};
