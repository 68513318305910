import { useEffect, useMemo, useState } from "react";
import { Address } from "viem";
import { useAccount, useReadContracts } from "wagmi";

import { IERC20Abi } from "@/abi/IERC20";
import { usePools } from "./usePools";
import { toW3Number, W3Number } from "@/lib/w3Num";

type Balances = Record<Address, W3Number>;

export const useBalances = () => {
  const [balances, setBalances] = useState<Balances | null>(null);

  const account = useAccount();
  const { poolAddrs, tokens, tokenAddrs } = usePools();

  // generate wagmi contracts calls
  const contracts = useMemo(() => {
    const tokenCalls = tokenAddrs.map(
      (addr) =>
        ({
          abi: IERC20Abi,
          address: addr as Address,
          functionName: "balanceOf",
          args: [account.address],
        } as const)
    );
    const lptCalls = poolAddrs.map(
      (addr) =>
        ({
          abi: IERC20Abi,
          address: addr as Address,
          functionName: "balanceOf",
          args: [account.address],
        } as const)
    );

    return [...tokenCalls, ...lptCalls];
  }, [account.address, poolAddrs, tokenAddrs]);

  const { data, isError, failureReason, refetch } = useReadContracts({
    allowFailure: false,
    contracts,
    query: {
      enabled: !!account.address,
    },
  });

  useEffect(() => {
    if (isError) {
      console.warn("Fetch balances:", failureReason?.message);
    }
  });

  useEffect(() => {
    if (data) {
      const newBalances: Balances = {};
      Array.from({ length: tokenAddrs.length }, (_, i) => {
        const tokenAddr = tokenAddrs[i];
        const bal = toW3Number(data[i], tokens[tokenAddr].decimals, 6);
        newBalances[tokenAddr] = bal;
      });

      Array.from({ length: poolAddrs.length }, (_, i) => {
        const poolAddr = poolAddrs[i];
        const _i = i + tokenAddrs.length;
        const bal = toW3Number(data[_i], 18, 6);
        newBalances[poolAddr] = bal;
      });
      setBalances(newBalances);
    }
  }, [tokens, poolAddrs, tokenAddrs, data]);

  return {
    balances,
    refetch,
  };
};
