import { useEffect, useMemo } from "react";
import { useGetBlockNumberQuery } from "@/generated/sedge-graphql";

const REFRESH_INTERVAL_MS = 20_000;

type LatestSubgraphBlockNum = {
  blockNum: number | null;
  blockTimestamp: number | null;
  hasIndexingErrors: boolean;
};

const initState: LatestSubgraphBlockNum = {
  blockNum: null,
  blockTimestamp: null,
  hasIndexingErrors: false,
};

export const useSubgraphBlockNumber = () => {
  const { data: subgraphData, error } = useGetBlockNumberQuery({
    pollInterval: REFRESH_INTERVAL_MS,
  });

  useEffect(() => {
    if (error) {
      console.log("Subgraph block num error:", error);
    }
  }, [error]);

  const data = useMemo<LatestSubgraphBlockNum>(() => {
    if (subgraphData && subgraphData._meta) {
      return {
        blockNum: subgraphData._meta.block.number,
        blockTimestamp: subgraphData._meta.block.timestamp || null,
        hasIndexingErrors: subgraphData._meta.hasIndexingErrors,
      };
    }

    return initState;
  }, [subgraphData]);

  return {
    ...data,
  };
};
