import { Pool } from "@/lib/pool-types";
import { formatDecimalString } from "@/lib/utils";
import { W3Number } from "@/lib/w3Num";

interface Props {
  pool: Pool;
  baseTokenAmount: W3Number | null;
  quoteTokenAmount: W3Number | null;
  oraclePrice: number | null;
}
export const RemoveLptStats: React.FC<Props> = ({
  pool,
  baseTokenAmount,
  quoteTokenAmount,
  oraclePrice,
}) => {
  return (
    <div className="flex flex-col text-sm">
      <div className="flex w-full">
        <div className="flex">You will receive</div>
        <div className="flex flex-grow flex-col font-semibold text-end">
          <span>
            {formatDecimalString(baseTokenAmount?.dsp, 0, 2)}{" "}
            {pool.baseToken.symbol}
          </span>
          <span>
            {formatDecimalString(quoteTokenAmount?.dsp, 0, 2)}{" "}
            {pool.quoteToken.symbol}
          </span>
        </div>
      </div>
      <div className="flex w-full">
        <div className="flex">Value</div>
        <div className="flex flex-grow flex-col font-semibold text-end">
          ${" "}
          {baseTokenAmount && quoteTokenAmount && oraclePrice
            ? (
                baseTokenAmount.dsp * oraclePrice +
                quoteTokenAmount.dsp
              ).toFixed(2)
            : "--"}
        </div>
      </div>
    </div>
  );
};
