import { useState } from "react";
import { Menu } from "lucide-react";
import { ScrollArea } from "@radix-ui/react-scroll-area";
import { Accordion } from "@radix-ui/react-accordion";
import { NavLink, useLocation } from "react-router-dom";

import { ConnectWalletButton } from "@/components/connect-wallet-button";
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/override/accordion";
import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from "@/components/ui/override/sheet";
import { MainMenu } from "@/config/menu";
import { cn } from "@/lib/utils";

export const MobileMenu = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <button className="p-2">
          <Menu size={24} />
        </button>
      </SheetTrigger>

      <SheetContent
        side="left"
        className="pr-0 sm:max-w-xs flex flex-col h-full"
      >
        <div className="flex-grow overflow-auto">
          <NavLink
            to="/"
            onClick={() => setOpen(false)}
            className="flex items-center space-x-2"
          >
            <img
              src="/assets/sedge-mobile-short.svg"
              alt="Sedge logo"
              className="w-[40%]"
            />
          </NavLink>

          <ScrollArea className="my-4 h-[calc(100vh-16rem)] pb-8 pl-8">
            <Accordion
              type="single"
              collapsible
              className="w-full"
              defaultValue={
                "item-" +
                MainMenu.findIndex((item) =>
                  item.items !== undefined
                    ? item.items
                        .filter((subitem) => subitem.to !== undefined)
                        .map((subitem) => subitem.to)
                        .includes(location.pathname)
                    : false
                )
              }
            >
              <div className="flex flex-col space-y-3">
                {MainMenu.map((menu, index) =>
                  menu.items !== undefined ? (
                    <AccordionItem
                      key={index}
                      value={`item-${index}`}
                      className="border-b-0 pr-6"
                    >
                      <AccordionTrigger
                        className={cn(
                          "py-1 hover:no-underline hover:text-primary [&[data-state=open]]:text-primary",
                          menu.items
                            .filter((subitem) => subitem.to !== undefined)
                            .map((subitem) => subitem.to)
                            .includes(location.pathname)
                            ? "text-foreground"
                            : "text-foreground/60"
                        )}
                      >
                        <div className="flex">{menu.title}</div>
                      </AccordionTrigger>
                      <AccordionContent className="pb-1 pl-4">
                        <div className="mt-1">
                          {menu.items.map((submenu, subindex) =>
                            submenu.to !== undefined ? (
                              <NavLink
                                key={subindex}
                                to={submenu.to}
                                onClick={() => setOpen(false)}
                                className={({ isActive }) =>
                                  cn(
                                    "block justify-start py-1 h-auto font-normal hover:text-primary",
                                    isActive
                                      ? "text-foreground"
                                      : "text-foreground/60"
                                  )
                                }
                              >
                                {submenu.title}
                              </NavLink>
                            ) : submenu.label !== "" ? null : (
                              <div className="px-3"></div>
                            )
                          )}
                        </div>
                      </AccordionContent>
                    </AccordionItem>
                  ) : (
                    <NavLink
                      key={index}
                      to={menu.to ?? ""}
                      onClick={() => setOpen(false)}
                      className={({ isActive }) =>
                        cn(
                          "py-1 text-lg font-medium transition-colors hover:text-primary",
                          isActive ? "text-foreground" : "text-foreground/60"
                        )
                      }
                    >
                      {menu.title}
                    </NavLink>
                  )
                )}
              </div>
            </Accordion>
          </ScrollArea>
        </div>
        <div className="mt-auto pb-8 flex justify-center w-full">
          <ConnectWalletButton />
        </div>
      </SheetContent>
    </Sheet>
  );
};
