import { useGetPoolsByDayDataQuery } from "@/generated/sedge-graphql";
import { useEffect, useMemo } from "react";
import { Address, getAddress } from "viem";
import { SubgraphPool } from "./usePools";

// Datetime utils
const millisecondsToSeconds = (ms: number) => Math.floor(ms / 1000);
const startOfDay = (d: Date) =>
  new Date(d.getFullYear(), d.getMonth(), d.getDate());
const endOfDay = (d: Date) =>
  new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1, 0, 0, 0, -1);

// Return a query search period from the start of day from X number of days back
// to end of today in seconds
const getPeriodUnixTime = (
  numDays: number,
): { periodStart: number; periodEnd: number } => {
  const now = new Date();
  const start = new Date(startOfDay(now).setDate(now.getDate() - numDays));

  return {
    periodStart: millisecondsToSeconds(start.getTime()),
    periodEnd: millisecondsToSeconds(endOfDay(now).getTime()),
  };
};

type PoolAprData = {
  apr: number;
  feeYtd: number;
  volumeYtd: number;
};

type AprData = Record<Address, PoolAprData>;

export const usePoolsApr = (pools: Record<Address, SubgraphPool>) => {
  const { periodStart, periodEnd } = getPeriodUnixTime(364);

  // daily pool subgraph query
  const {
    data: rawSubgraphData,
    // loading: dailySubgraphLoading,
    error: subgraphError,
  } = useGetPoolsByDayDataQuery({
    variables: {
      dateGte: periodStart,
      dateLt: periodEnd,
    },
  });

  useEffect(() => {
    if (subgraphError) {
      console.log(subgraphError);
    }
  }, [subgraphError]);

  const data = useMemo<AprData>(() => {
    if (pools && rawSubgraphData) {
      const subgraphDataStart = Number(
        rawSubgraphData.pairDayDatas[rawSubgraphData.pairDayDatas.length - 1]
          .date,
      );
      const rawScale = (periodEnd - subgraphDataStart) / (365 * 24 * 60 * 60);
      const scaler = rawScale > 1 ? 1 : 1 / rawScale;

      return rawSubgraphData.pairDayDatas.reduce((prev, curr) => {
        const id = getAddress(curr.id.substring(0, 42));

        if (!prev[id]) {
          prev[id] = {
            apr: 0,
            feeYtd: 0,
            volumeYtd: 0,
          };
        }

        prev[id].feeYtd += Number(curr.feeUSD);
        prev[id].volumeYtd += Number(curr.volumeUSD);
        prev[id].apr = pools[id]
          ? ((prev[id].feeYtd * scaler) / pools[id].reserveUsd) * 100
          : 0;

        return prev;
      }, {} as AprData);
    }
    return {};
  }, [pools, rawSubgraphData, periodEnd]);

  return { ...data };
};
