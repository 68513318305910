import { useCallback, useEffect, useMemo, useState } from "react";
import { useAtomValue } from "jotai";
import { formatUnits } from "viem";
import { useWriteContract } from "wagmi";

import { IDfxCurveV3Abi } from "@/abi/IDfxCurveV3";
import { txSettingsAtom } from "@/contexts/atoms";
import { Pool } from "@/lib/pool-types";
import { getDeadlineEpochTime } from "@/lib/time-utils";
import { sleep } from "@/lib/utils";
import { W3Number } from "@/lib/w3Num";
import { calcAmountWithSlippage } from "../useCalcSwap";
import { APPROVE_DELAY_MS, useApprove } from "../useApprove";
import {
  calcMaxBaseForDeposit,
  calcMaxQuoteForDeposit,
} from "./liquidity-utils";
import { toast } from "../useToast";

export enum LiquidityField {
  ExactTokenA = 0,
  ExactTokenB = 1,
}

export const useLiquidityDepositTwoSided = (
  pool: Pool,
  amountA: W3Number | null,
  amountB: W3Number | null,
  setAmountA: (val: W3Number | null) => void,
  setAmountB: (val: W3Number | null) => void,
  exactToken: LiquidityField
) => {
  const [lptAmount, setLptAmount] = useState<bigint | null>(null);
  const txSettings = useAtomValue(txSettingsAtom);

  const { writeContractAsync } = useWriteContract();
  const {
    approveRequired: approveBaseRequired,
    executeApprove: executeApproveBase,
  } = useApprove(pool.baseToken.id, pool.id, amountA);
  const {
    approveRequired: approveQuoteRequired,
    executeApprove: executeApproveQuote,
  } = useApprove(pool.quoteToken.id, pool.id, amountB);

  // Calc other side when tokenA is edited
  useEffect(() => {
    const calcDepositTokenB = async () => {
      if (exactToken === LiquidityField.ExactTokenA) {
        if (amountA) {
          const { amountB, lptAmount: lptAmount_ } =
            await calcMaxQuoteForDeposit(
              pool,
              amountA,
              pool.quoteToken.decimals
            );
          setLptAmount(lptAmount_);
          setAmountB(amountB);
        } else {
          setLptAmount(null);
          setAmountB(null);
        }
      }
    };

    calcDepositTokenB();
  }, [pool, exactToken, amountA, setAmountB]);

  // Calc other side when tokenB is edited
  useEffect(() => {
    const calcDepositTokenA = async () => {
      if (exactToken === LiquidityField.ExactTokenB) {
        if (amountB) {
          const { amountA, lptAmount: lptAmount_ } =
            await calcMaxBaseForDeposit(pool, amountB, pool.baseToken.decimals);
          setLptAmount(lptAmount_);
          setAmountA(amountA);
        } else {
          setLptAmount(null);
          setAmountA(null);
        }
      }
    };

    calcDepositTokenA();
  }, [pool, exactToken, amountB, setAmountA]);

  // Calc deposit value
  const lptValueUsd = useMemo(() => {
    if (lptAmount) {
      return parseFloat(formatUnits(lptAmount, 18)) * pool.lptPrice;
    }
    return null;
  }, [lptAmount, pool.lptPrice]);

  // Calc pool percent
  const lptPercentToReceive = useMemo(() => {
    if (lptAmount) {
      const toReceive = parseFloat(formatUnits(lptAmount, 18));
      const totalSupply = parseFloat(pool.lptTotal);
      return (100 * toReceive) / (toReceive + totalSupply);
    }
    return null;
  }, [lptAmount, pool.lptTotal]);

  const deposit = useCallback(async () => {
    if (lptAmount && amountA && amountB) {
      const maxSlippagePercent =
        parseFloat(txSettings.maxSlippagePercent) * 100;

      if (maxSlippagePercent > 100) {
        throw new Error("maxSlippagePercent must be less than 100");
      }
      const depositAmountWithSlippage = calcAmountWithSlippage(
        lptAmount,
        10 // Slippage protection for first deposit (v3 update)
      );

      const minBase = calcAmountWithSlippage(amountA.big, maxSlippagePercent);
      const minQuote = calcAmountWithSlippage(amountB.big, maxSlippagePercent);

      // Apply reverse slippage to side of transaction that has not been edited by user
      const maxBase = calcAmountWithSlippage(
        amountA.big,
        maxSlippagePercent,
        true
      );
      const maxQuote = calcAmountWithSlippage(
        amountB.big,
        maxSlippagePercent,
        true
      );

      const epochDeadline = BigInt(
        getDeadlineEpochTime(txSettings.minutesToTxDeadline)
      );

      console.log("approve base required", approveBaseRequired);
      if (approveBaseRequired) {
        toast({ title: `Approving ${pool.baseToken.symbol}...` });
        const res = await executeApproveBase();
        if (res === null) return; // early exit: approval failed
        toast({
          title: `Approval for ${pool.baseToken.symbol} successful`,
        });

        await sleep(APPROVE_DELAY_MS); // X second delay
      }

      console.log("approve quote token required", approveQuoteRequired);
      console.log(approveQuoteRequired);
      if (approveQuoteRequired) {
        toast({ title: `Approving ${pool.quoteToken.symbol}...` });
        const res = await executeApproveQuote();
        if (res === null) return; // early exit: approval failed
        toast({
          title: `Approval for ${pool.quoteToken.symbol} successful`,
        });

        await sleep(APPROVE_DELAY_MS); // X second delay
      }

      toast({ title: "Adding liquidity..." });
      await writeContractAsync({
        abi: IDfxCurveV3Abi,
        address: pool.id,
        functionName: "deposit",
        args: [
          depositAmountWithSlippage,
          minQuote,
          minBase,
          maxQuote,
          maxBase,
          epochDeadline,
        ],
      });
      toast({
        title: `Successfully added ${pool.quoteToken.symbol}/${pool.baseToken.symbol} liquidity`,
      });
    }
  }, [
    pool.id,
    pool.quoteToken.symbol,
    pool.baseToken.symbol,
    amountA,
    amountB,
    lptAmount,
    txSettings,
    approveBaseRequired,
    approveQuoteRequired,
    executeApproveBase,
    executeApproveQuote,
    writeContractAsync,
  ]);

  return {
    deposit,
    lptAmount,
    lptValueUsd,
    lptPercentToReceive,
  };
};
