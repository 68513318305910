import React, { useState } from "react";

import { LiquidityTokenInput } from "./liquidity-token-input";
import { Button } from "../ui/button";
import {
  LiquidityField,
  useLiquidityDepositTwoSided,
} from "@/hooks/useLiquidityDeposit/use-two-sided";
import { Pool } from "@/lib/pool-types";
import { W3Number } from "@/lib/w3Num";
import { AddLptStats } from "./add-lpt-stats";

interface Props {
  pool: Pool;
  closeModal: () => void;
}
export const AddTwoSidedFields: React.FC<Props> = ({ pool, closeModal }) => {
  const [amountTokenA, setAmountTokenA] = useState<W3Number | null>(null);
  const [amountTokenB, setAmountTokenB] = useState<W3Number | null>(null);
  const [exactToken, setExactToken] = useState(LiquidityField.ExactTokenA);

  const { deposit, lptAmount, lptValueUsd, lptPercentToReceive } =
    useLiquidityDepositTwoSided(
      pool,
      amountTokenA,
      amountTokenB,
      setAmountTokenA,
      setAmountTokenB,
      exactToken
    );

  const handleSubmitDeposit = async () => {
    if (deposit) {
      await deposit();

      closeModal();
    }
  };

  return (
    <>
      <LiquidityTokenInput
        direction="deposit"
        token={pool.baseToken}
        inputValue={amountTokenA}
        onChange={(val) => {
          setAmountTokenA(val);
          setExactToken(LiquidityField.ExactTokenA);
        }}
      />
      <LiquidityTokenInput
        direction="deposit"
        token={pool.quoteToken}
        inputValue={amountTokenB}
        onChange={(val) => {
          setAmountTokenB(val);
          setExactToken(LiquidityField.ExactTokenB);
        }}
      />

      {/* Added LPT stats */}
      <AddLptStats
        lptAmount={lptAmount}
        lptValueUsd={lptValueUsd}
        lptPercentToReceive={lptPercentToReceive}
      />

      {/* Add Liquidity Button */}
      <Button
        className="w-full dark:bg-blue-600 dark:hover:bg-blue-700 text-lg py-6"
        onClick={handleSubmitDeposit}
      >
        Add Liquidity
      </Button>
    </>
  );
};
