import { cn } from "@/lib/utils";

interface Props {
  heightClass: string;
  widthClass: string | number;
  color: string;
}
export const PingDot: React.FC<Props> = ({
  heightClass,
  widthClass,
  color,
}) => {
  return (
    <div className={cn("relative", heightClass, widthClass)}>
      <div className={cn("absolute rounded", heightClass, widthClass, color)} />
      <div
        className={cn(
          "absolute rounded opacity-50",
          heightClass,
          widthClass,
          color,
          `animate-[ping_1.5s_cubic-bezier(1,1,0.1,0)_infinite]`
        )}
      />
    </div>
  );
};
