import { PoolHealth, PoolParams, Token } from "./pool-types";

export const calcPoolRatio = (
  baseToken: Token,
  quoteToken: Token,
  oraclePrice: number
) => {
  if (oraclePrice && baseToken.amount && quoteToken.amount) {
    const baseVal = baseToken.amount * oraclePrice;
    const quoteVal = quoteToken.amount * 1;
    const baseRatio = baseVal / (baseVal + quoteVal);
    return [baseRatio, 1 - baseRatio];
  }
  return [];
};

// Interpolates [x, y] for arbitrary values of x.
// Yields initialized control point coordinates in the format of [x, maxValue-x+1].
const initControlPoints = (
  weight: number,
  coef: number,
  maxValue: number,
  lower?: boolean
) => {
  const x = lower
    ? (weight - weight * coef) * maxValue
    : (weight + weight * coef) * maxValue;
  const y = maxValue - x + 1;
  return [x, y];
};

/* Sedge Curve */
// An implementation of the formal model from Shell Protocol bonding curve
const applySedgeCurve = (
  x: number,
  y: number,
  weight0: number,
  weight1: number,
  beta: number,
  delta: number,
  feeMax: number
) => {
  const weights = [weight0, weight1];

  const Sum = (x: number[]) => {
    return x.reduce((acc, y) => acc + y, 0);
  };
  const G = Sum;

  const I = (i: number, x: number[]) => {
    return G(x) * weights[i];
  };

  const L = (i: number, x: number[]) => {
    return I(i, x) * (1.0 - beta);
  };

  const V = (i: number, x: number[]) => {
    return I(i, x) * (1.0 + beta);
  };

  const M = (i: number, x: number[]) => {
    return Math.max(L(i, x) - x[i], x[i] - V(i, x), 0);
  };

  const m = (i: number, x: number[]) => {
    return (delta * M(i, x)) / I(i, x);
  };

  const f = (i: number, x: number[]) => {
    return M(i, x) * Math.min(m(i, x), feeMax);
  };

  const F = (x: number[]) => {
    return Sum(x.map((_, i) => f(i, x)));
  };

  return F([x, y]);
};

// Calculates the slippage and halt boundary control points on DFX curve
const applySedgeControlPoint = (
  point: number[],
  weight0: number,
  weight1: number,
  beta: number,
  delta: number,
  feeMax: number
) => {
  const out = applySedgeCurve(
    point[0],
    point[1],
    weight0,
    weight1,
    beta,
    delta,
    feeMax
  );
  if (point[0] < 50) {
    return {
      x: point[0],
      y: point[1] + out,
    };
  }
  return {
    x: point[0] + out,
    y: point[1],
  };
};

export const calcSedgeControlPoints = (
  maxValue: number,
  weight0: number,
  weight1: number,
  poolParams: PoolParams
) => {
  const { alpha, beta } = poolParams;
  const params = [
    weight0,
    weight1,
    poolParams.beta,
    poolParams.delta,
    poolParams.lambda,
  ] as const;

  // min slippage zone
  const betaLowerXY = initControlPoints(weight0, beta, maxValue, true);
  const b = applySedgeControlPoint(betaLowerXY, ...params);

  // max slippage zone
  const betaUpperXY = initControlPoints(weight1, beta, maxValue);
  const c = applySedgeControlPoint(betaUpperXY, ...params);

  // min halt boundary;
  const alphaLowerXY = initControlPoints(weight0, alpha, maxValue, true);
  const d = applySedgeControlPoint(alphaLowerXY, ...params);

  // max halt boundary
  const alphaUpperXY = initControlPoints(weight1, alpha, maxValue);
  const e = applySedgeControlPoint(alphaUpperXY, ...params);

  return {
    slippageZones: [
      { ...b, y: b.y - 1 },
      { ...c, y: c.y - 1 },
    ],
    haltBoundaries: [d, e],
  };
};

export const calcPoolHealth = (
  ratio0: number,
  healthyMin: number,
  healthyMax: number
): PoolHealth => {
  if (ratio0 >= healthyMin / 100 && ratio0 <= healthyMax / 100) {
    return "balanced";
  }
  return "imbalanced";
};
